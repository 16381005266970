<template>
  <section class="house-house-plan">
    <section class="hero-small">
      <div>
        <h1>
          Des plans de maisons intelligents
        </h1>
      </div>
    </section>
    <div class="grid-container">
      <div class="intro">
        <h6>
          Pratiques, conviviales et optimisées : <br>
          nous avons conçu des maisons où les espaces répondent aux modes de vie actuels.
        </h6>
      </div>

      <card-type-img
        title="Des plans de maisons bien pensés">
        <template v-slot:content>
          <p>
            Cuisine ouverte, cellier, suite parentale, chambre d’amis, bureau, rangements…
            vous trouverez surement la maison qui vous correspond. Au-delà de vos attentes, nos
            plans de maisons sont personnalisables en offrant la possibilité de moduler la surface
            des pièces de vie selon vos besoins et selon les caractéristiques du terrain.
            Une exclusivité Maisons Alysia.
          </p>
        </template>
        <template v-slot:img>
          <img src="@/assets/img/pages/living-room-house.jpg" alt="Salon de maison" />
        </template>
      </card-type-img>

      <card-type-img
        title="Des plans adaptables aux terrains">
        <template v-slot:content>
          <p>
            Notre configurateur permet, en fonction du lot que vous sélectionnez, de procéder automatiquement à l’ajustement réglementaire automatique du plan de la maison au terrain. Vous en êtes immédiatement averti : les caractéristiques et les incidences de cet agrandissement sont précisées dans le descriptif du lot (longueur en façade, surface habitable, prix) et impactent le prix total de votre projet.
          </p>
          <p>
            Pour mémoire, le règlement d’un lotissement stipule généralement que la  distance d’une construction des limites séparatives du lot doit être nulle (mitoyenneté) ou supérieure ou égale à 3 m. Si elle est inférieure à 3 m, l’agrandissement est automatique.
          </p>
        </template>
        <template v-slot:img>
          <img src="@/assets/img/pages/grow-house.jpg" alt="Aggrandir sa maison" />
        </template>
      </card-type-img>

      <card-type-img
        title="La personnalisation de la superficie de nos plans de maisons">
        <template v-slot:content>
          <p>
            A partir d’un modèle de maison, vous pouvez agrandir le plan à partir de la pièce de vie et donc personnaliser votre superficie (dans les limites spécifiques affectées à chaque plan de maison). <br>
              Vous avez la possibilité d’accéder à cette fonctionnalité au sein même du configurateur Maisons Alysia dans le module maison (onglet « surface ») et ainsi d’obtenir instantanément le prix de votre modèle modifié par vos soins. Ou encore étudier cette éventualité avec votre constructeur.
          </p>
        </template>
        <template v-slot:img>
          <img src="@/assets/img/pages/surfaces-screen.jpg" alt="Surfaces de maison" class="contain-img"/>
        </template>
      </card-type-img>

      <card-type-img
        title="Un prix du m2 supplémentaire défiant toute concurrence !">
        <template v-slot:content>
          <p>
            Selon vos besoins et vos souhaits, le plan de maison que vous avez sélectionné est personnalisable. <br>
            Notre process de fabrication est rationalisé et totalement maîtrisé : quel qu’il soit, votre agrandissement vous coûtera 1000 € le m2 supplémentaire (en plain-pied). <br>
            Qui peut en dire autant ? Ou plutôt… pour si peu.
          </p>
        </template>
        <template v-slot:img>
          <img src="@/assets/img/pages/1000.png" alt="1000 € du m2" class="contain-img"/>
        </template>
      </card-type-img>
    </div>
  </section>
</template>

<script>
import CardTypeImg from '@/components/general/CardTypeImg.vue';

export default {
  name: 'house-house-plan',
  components: {
    CardTypeImg,
  },
};
</script>

<style lang="sass">
  .house-house-plan
    background: $medium-bg
    .hero-small
      @include hero-small
      background-image: linear-gradient(180deg, rgba(10, 10, 10, 0.6) 0%, rgba(32, 32, 32, 0.2) 100%), url(~@/assets/img/pages/girl-father.jpg)

    .intro
      margin: 6rem auto 5rem
      padding: 0 1rem
      max-width: 925px
      text-align: center

    .card-type-img
      margin: 3rem auto
      p ~ p
        margin-top: 1.5rem

        @media (max-width: 768px)
          margin-right: 0

      img.contain-img
        object-fit: contain
        width: 95%

</style>
